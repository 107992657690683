import React, { useState, useEffect, useRef } from 'react';
import './MediaGrid.css';
import './Watchlist.css';
import 'font-awesome/css/font-awesome.min.css';
import { addToWatchlist } from './utils';
import RatingBadge from './RatingBadge';
import { useToast } from './ToastContext';
import { useNavigate } from 'react-router-dom';

const MediaGrid = ({ mediaType, onRecommendationsFetched }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [mediaItems, setMediaItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const mediaGridRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);  // Start loading
    fetch(`/trending/daily/${mediaType}`)
      .then(response => response.json())
      .then(data => {
        // sort by popularity
        data.searchResults.results.sort((a, b) => b.popularity - a.popularity);
        
        setMediaItems(data.searchResults.results);
        setSelectedItem({});
        setIsLoading(false);  // End loading
      });
  }, [mediaType]);

  const handleItemClick = (item, event, actionType) => {
    event.stopPropagation();
    switch (actionType) {
      case 'add':
        addToWatchlist(item, event, onRecommendationsFetched)
          .then(response_data => showToast(response_data.fun_fact));
        break;
      case 'delete':
        setMediaItems(currentItems => currentItems.filter(current => current.id !== item.id));
        break; 
      default:
        const url = `/details/${item.id}/${encodeURIComponent(item.title || item.name)}/${new Date(item.release_date).getFullYear()}/true?media_type=${mediaType}`;
        navigate(url);
    }
  };

  const placeholderItems = new Array(10).fill(0).map((_, index) => (
    <div key={`placeholder-${index}`} className="media-item placeholder" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px' }}>
      <i className="fa fa-file-image-o" style={{ fontSize: '100px', color: '#ccc' }}></i>
    </div>
  ));

  return (
    <>
      <div className="media-grid-container" style={{ margin: "10px" }} ref={mediaGridRef}>
        {isLoading ? placeholderItems : mediaItems.map((item) => (
          <div key={item.id} className={`media-item ${selectedItem.id === item.id ? 'selected-effect' : 'bw-effect'}`}>
            <div onClick={(e) => handleItemClick(item, e)}>
              <img style={{ padding: "10px", borderRadius: "20px" }} src={`https://image.tmdb.org/t/p/w500/${item.poster_path}`} alt={item.title || item.name} />
              <div className="overlay">
                <button onClick={(e) => handleItemClick(item, e, 'add')} className="add-watchlist-btn">
                  <i className="fa fa-plus"></i> Add
                </button>
                <RatingBadge voteAverage={item.vote_average} voteCount={item.vote_count} />
                <div className="delete-icon-btn" onClick={(e) => handleItemClick(item, e, 'delete')}>
                  <i className="fa fa-times"></i>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MediaGrid;