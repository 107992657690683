import React, { useEffect, useState, useMemo } from 'react';
import Cookies from 'js-cookie';

const ProfilePage = () => {
  const [watchlistNames, setWatchlistNames] = useState({});
  const accessedWatchlists = Cookies.get('accessedUsernames');

  const watchlistArray = useMemo(() => accessedWatchlists ? accessedWatchlists.split(',') : [], [accessedWatchlists]);

  useEffect(() => {
    // Set the title of the window to 'Profile'
    document.title = 'Watchlists';
    watchlistArray.forEach(username => {
      fetch(`/api/watchlist_name/${username}`)
        .then(response => response.json())
        .then(data => {
          let _name = `${data.name ? `${data.name}` : ''}`;
          if (typeof(data.name) !== "string") {
            _name = `unnamed #${watchlistArray.indexOf(username) + 1}`;
          }
          setWatchlistNames(prevNames => ({ ...prevNames, [username]: _name }));
        })
        .catch(error => console.error('Error fetching watchlist name:', error));
    });
  }, [watchlistArray]);

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      alert(`Link copied to clipboard`);
    }, (err) => {
      console.error('Error copying link: ', err);
    });
  };

  const createNewWatchlist = () => {
    const randomHash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    Cookies.remove("username"); 
    Cookies.set("username", randomHash, { expires: 30 });
    window.location.href = `/watchlist?w=${randomHash}`;
  };

  const handleDelete = (watchlist) => {
    if (window.confirm('Are you sure you want to delete this watchlist?')) {
      const updatedWatchlists = watchlistArray.filter(w => w !== watchlist).join(',');
      Cookies.set('accessedUsernames', updatedWatchlists);
      localStorage.removeItem(`sortedWatchList-${watchlist}`);
      window.location.reload();
    }
  };

  return (
    <div className="profile-page-container" style={{ marginTop: "20px" }}>
      <br/>
      <h2>Your Profile</h2>
      <p>Whats your personality profile, and character alignment?</p>
      <p>Feel free to share the following links with your friends to let them view and edit your watchlists.</p>
      <h2>Shared Watchlists</h2>
      <p>Be sure to bookmark or save these links to avoid losing them. If you clear your cache, this list no longer appears.</p>
      <button onClick={createNewWatchlist} style={{ margin: '20px 0', padding: '10px 20px', borderRadius: '5px', backgroundColor: '#61DAFB', color: '#000', border: 'none', cursor: 'pointer' }}>
        Create New Watchlist
      </button>
      <div style={{ marginTop: '20px' }}>
        {watchlistArray.length > 0 ? (
          <div style={{ padding: '10px', backgroundColor: "#282C34", borderRadius: '10px' }}>
            {watchlistArray.map((watchlist, index) => {
              const watchlistUrl = `${window.location.origin}/watchlist?w=${watchlist}`;
              return (
                <div key={index} style={{ margin: '10px 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <a href={watchlistUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: '#61DAFB' }}>
                    {watchlistNames[watchlist] ? watchlistNames[watchlist] : `View Watchlist #${index + 1}`}
                  </a>
                  <div>
                    <button onClick={() => copyToClipboard(watchlistUrl)} style={{ padding: '5px 10px', borderRadius: '5px', backgroundColor: '#61DAFB', color: '#000', border: 'none', cursor: 'pointer', marginRight: '10px' }}>
                      Copy Link
                    </button>
                    <button onClick={() => handleDelete(watchlist)} style={{ padding: '5px 10px', borderRadius: '5px', backgroundColor: 'red', color: '#fff', border: 'none', cursor: 'pointer' }}>
                      Delete
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p>No shared watchlists found. Start by creating and visiting watchlists to see them here.</p>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
