import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './HomePage.css';
import SearchBar from './SearchBar';
import MediaGrid from './MediaGrid';

const HomePage = () => {
  const [currentView, setCurrentView] = useState('tv');  // Default to 'tv'
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  document.title = 'Discover';

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('s');
    if (searchQuery) {
      setSearchTerm(searchQuery);
    }
  }, [location.search]);

  const handleTabClick = (view) => {
    setCurrentView(view);
  };

  return (
    <div>
      <SearchBar initialSearchTerm={searchTerm} />
      <div className="media-type-selector">
        <h5 style={{ marginLeft: "17px" }} className={currentView === 'tv' ? 'active-tab' : 'inactive-tab'} onClick={() => handleTabClick('tv')} >
          POPULAR TV
        </h5> <h5 style={{ color: "gray" }}>  : </h5>
        <h5 className={currentView === 'movies' ? 'active-tab' : 'inactive-tab'} onClick={() => handleTabClick('movies')} >
          POPULAR MOVIES
        </h5>
      </div>
      {currentView === 'tv' && (
        <>
          <MediaGrid mediaType="tv" searchTerm={searchTerm} />
        </>
      )}
      {currentView === 'movies' && (
        <>
          <MediaGrid mediaType="movies" searchTerm={searchTerm} />
        </>
      )}
    </div>
  );
};

export default HomePage;
