import Cookies from 'js-cookie';

export const fetchProviders = async (item, isFetchingProviders, setIsFetchingProviders, setProviderData, fetchAPI) => {
    if (item.providers || isFetchingProviders[item.id]) return;

    setIsFetchingProviders(prev => ({ ...prev, [item.id]: true }));
    const [data, error] = await fetchAPI(`/w/providers?tmdb_id=${item.tmdb_id}&media_type=${item.media_type}&rec_id=${item.id}`);

    if (!error) setProviderData(prev => ({ ...prev, [item.id]: data.providers || [] }));
    setIsFetchingProviders(prev => ({ ...prev, [item.id]: false }));
};
export const sortItems = (items, criteria) => {
    switch (criteria) {
        case 'name':
            return [...items].sort((a, b) => a.title.localeCompare(b.title));
        case 'random':
            return items.sort(() => Math.random() - 0.5);
        case 'rating':
            return [...items].sort((a, b) => b.vote_average - a.vote_average);
        case 'dateAdded':
            return [...items].sort((a, b) => new Date(b.Created) - new Date(a.Created));
        default:
            return [...items].sort((a, b) => new Date(b.Created) - new Date(a.Created));
    }
};

// Function to delete an item from the watchlist
export const handleDeleteItem = (mediaId, event, location, setUndoAvailable) => {
    console.log('Handling delete for item:', mediaId);
    console.log('Deleting item:', mediaId);
    console.log(mediaId);
    event.stopPropagation();
    localStorage.setItem('reset_watchlist_cache', 'true'); 
    const username = getQueryParamValue(location.search, 'w');
    fetch(`/w/${username}/delete/${mediaId}`, { method: 'GET' })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Mark the item as undoAvailable
                setUndoAvailable(prev => ({ ...prev, [mediaId]: true }));
            }
        })
        .catch(error => console.error('Error deleting item:', error));
};

// Function to parse query parameters
const getQueryParamValue = (query, param) => {
    const urlParams = new URLSearchParams(query);
    return urlParams.get(param);
};
// Function to undo delete (add back to watchlist)
export const undoDeleteItem = (tmdb_id, title, media_type, setUndoAvailable, fetchWatchlistItems) => {
    const username = Cookies.get("username");
    if (username) {
        fetch(`/w/${username}/add/${tmdb_id}?title=${encodeURIComponent(title)}&media_type=${media_type}`, { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Remove the item from the undoAvailable list
                    setUndoAvailable(prev => {
                        const newState = { ...prev };
                        // Use Object.entries to find the key for the given tmdb_id
                        const entry = Object.entries(prev).find(([, value]) => value.tmdb_id === tmdb_id);
                        if (entry) {
                            const [keyToRemove] = entry;
                            delete newState[keyToRemove];
                        }
                        return newState;
                    });
                    // Refresh the watchlist items to reflect the re-added item
                    fetchWatchlistItems(username);
                }
            })
            .catch(error => console.error('Error re-adding item:', error));
    } else {
        // If the username is not set, prompt for it
        checkAndPromptForUsername();
    }
};

// Function to check and prompt for username
const checkAndPromptForUsername = () => {
    let username = Cookies.get("username");
    if (!username) {
        let newListName = prompt("Please enter your email address for your watchlist:");
        if (newListName) {
            Cookies.set("username", newListName, { expires: 30 });
            window.location.reload();
        }
    }
};

export const hasItemsInWatchNow = (itemStatuses) => {
    return Object.values(itemStatuses).includes('watchNow');
};

export const processAndSaveWatchlistData = (data, itemStatuses) => {
    let allItems = [];

    // Process movies and TV shows
    if (Array.isArray(data.movies)) {
        allItems = allItems.concat(data.movies.map(item => ({
            ...item[1],
            id: item[0]
        })));
    }

    if (Array.isArray(data['tv-shows'])) {
        allItems = allItems.concat(data['tv-shows'].map(item => ({
            ...item[1],
            id: item[0]
        })));
    }

    // Generate initial statuses
    let initialStatuses = {};
    allItems.forEach(item => {
        if (!(item.id in itemStatuses)) {
            initialStatuses[item.id] = 'watchLater';
        }
    });

    // Save to localStorage if there are new statuses
    if (Object.keys(initialStatuses).length > 0) {
        const newStatuses = { ...itemStatuses, ...initialStatuses };
        localStorage.setItem('itemStatuses', JSON.stringify(newStatuses));
        return [allItems, newStatuses];
    }

    return [allItems, itemStatuses];
};



