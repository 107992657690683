import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './Watchlist.css';
import { fetchProviders, sortItems, processAndSaveWatchlistData } from './watchlistHelpers';
import { fetchAPI } from './utils';

import RatingBadge from './RatingBadge';
import 'font-awesome/css/font-awesome.min.css';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';

const Watchlist = () => {
  const [itemStatuses, setItemStatuses] = useState(() => {
    return JSON.parse(localStorage.getItem('itemStatuses')) || {};
  });
  const [isFetchingProviders, setIsFetchingProviders] = useState({});
  const [watchlistItems, setWatchlistItems] = useState([]);
  const [undoAvailable, setUndoAvailable] = useState({});
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [providerData, setProviderData] = useState({});
  const [sortCriteria, setSortCriteria] = useState('added');
  const [currentView, setCurrentView] = useState('tv');
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isNameLoading, setIsNameLoading] = useState(false);
  const [watchlistName, setWatchlistName] = useState('Your Watchlist: Share this with friends and family');
  const username = Cookies.get("username") || 'default';
  const [isEditingName, setIsEditingName] = useState(false);
  const [tempWatchlistName, setTempWatchlistName] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const mediaGridRef = useRef(null);
  const [bookmarked, setBookmarked] = useState(new Set());
  const [bookmarkedView, setBookmarkedView] = useState(false);
  const [activeGenre, setActiveGenre] = useState('All');
  const tv_genres = [{ id: 'All', name: 'All' }, { "id": 10759, "name": "Action" }, { "id": 35, "name": "Comedy" }, { "id": 18, "name": "Drama" }, { "id": 10751, "name": "Family" }, { "id": 10764, "name": "Reality" }, { "id": 10765, "name": "Sci-Fi & Fantasy" }];
  const movie_genres = [{ id: 'All', name: 'All' }, { "id": 28, "name": "Action" }, { "id": 35, "name": "Comedy" }, { "id": 18, "name": "Drama" }, { "id": 878, "name": "Science Fiction" }, { "id": 27, "name": "Horror" }, { "id": 10749, "name": "Romance" }, { "id": 53, "name": "Thriller" }];

  useEffect(() => {
    document.title = `${watchlistName} - Watchlist`;
  }, [watchlistName]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let mediaTypeParam = searchParams.get('m');
    if (mediaTypeParam === 'movies') {
      mediaTypeParam = "movie"
    }

    if (mediaTypeParam === 'tv' || mediaTypeParam === 'movie') {
      setCurrentView(mediaTypeParam);
    }
  }, [location.search]);

  useEffect(() => {
    fetchWatchlistItems(username);
  }, [username, location.search]);

  const changeView = (viewType) => {
    setCurrentView(viewType);
    setActiveGenre('All');
  };
 

  const isBookmarked = (itemId) => bookmarked.has(itemId);

  const handleWatchlistNameChange = (event) => {
    setTempWatchlistName(event.target.value);
  };

  const handleWatchlistNameSave = async (event) => {
    if (event.key === 'Enter') {
      try {
        const response = await fetch(`/api/save_watchlist/${username}?name=${encodeURIComponent(tempWatchlistName)}`, { method: 'GET' });
        if (!response.ok) throw new Error('Failed to save watchlist name');
        setWatchlistName(tempWatchlistName);
        setIsEditingName(false);
      } catch (error) {
        console.error('Error saving watchlist name:', error);
        setTempWatchlistName(watchlistName);
        setIsEditingName(false);
      }
    }
  };


  useEffect(() => {
    const usernameQuery = new URLSearchParams(location.search).get('w') || 'default';
    const username = decodeURIComponent(usernameQuery).toLowerCase();
    Cookies.set("username", username, { expires: 30 });
    const accessedUsernames = Cookies.get('accessedUsernames');
    const usernameArray = accessedUsernames ? accessedUsernames?.split(',') : [];
    if (!usernameArray.includes(username)) {
      usernameArray.push(username);
      Cookies.set('accessedUsernames', usernameArray.join(','), { expires: 30 });
    }
  }, [location.search]);

  useEffect(() => {
    const sortedItems = sortItems(watchlistItems, sortCriteria);
    setWatchlistItems(sortedItems);
  }, [sortCriteria]);

  const fetchAndUpdateWatchlistName = async () => {
    setIsNameLoading(true);
    try {
      const response = await fetch(`/api/watchlist_namer/${username}`);
      if (!response.ok) throw new Error('Failed to fetch watchlist name');
      const data = await response.json();
      const colonIndex = data.name.indexOf(':');
      const shortName = colonIndex !== -1 ? data.name.substring(0, colonIndex) : data.name;
      Cookies.set('watchlistName', data.name, { expires: 30 });
      Cookies.set('watchlistShortName', shortName, { expires: 30 });
      setWatchlistName(data.name);
    } catch (error) {
      console.error('Error fetching watchlist name:', error);
    }
    setIsNameLoading(false);
  };

  const handleGenerateNameClick = () => {
    fetchAndUpdateWatchlistName();
  };

  const copyToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      alert('URL copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy URL: ', err);
    });
  };

  const renderEmptyMessage = () => (
    <h3 className="empty-watchlist-message">
      <br />
      <center>Looks like your watchlist is empty! <br /><p />Go
        <a href="/search-page" className="link-homepage"> discover </a>
        content to add to your watchlist.
      </center>
    </h3>
  );

  const renderWatchlistContent = () => (
    <div style={{ marginTop: "10px" }}>
      {renderWatchlistNameOrButton()}
      <div className="top-controls">
        <div className="media-type-selector">
           
          {!location.search.includes('m=') && (
            <>
              <h5
                className={`media-type-option ${currentView === 'tv' ? 'active' : ''}`}
                onClick={() => changeView('tv')}
              >
                TV SHOWS
              </h5>
              <h5>{' : '} </h5>
              <h5
                className={`media-type-option ${currentView === 'movie' ? 'active' : ''}`}
                onClick={() => changeView('movie')}
              >
                MOVIES
              </h5>
            </>
          )}
        </div>
        
      </div>
      <div>{renderGenreFilters()}</div>
      <div className="watchlist-container" ref={mediaGridRef} style={{ marginTop: "15px" }}>
        {renderItems()}
      </div>
    </div>
  );

  const renderGenreFilters = () => {
    const genreList = currentView === 'tv' ? tv_genres : movie_genres;
    const availableGenreIds = new Set();
    watchlistItems.forEach(item => {
      if (item.media_type === (currentView === 'tv' ? 'tv' : 'movie')) {
        item.genre_ids?.split(',').forEach(genreId => availableGenreIds.add(genreId.trim()));
      }
    });
    const filteredGenres = genreList.filter(genre => availableGenreIds.has(String(genre.id)) || genre.id === 'All');
    return (
      <div className="genre-filters">
        {filteredGenres.map(genre => (
          <button
            key={genre.id}
            onClick={() => setActiveGenre(genre.id)}
            style={{
              margin: '0 0px',
              padding: '5px 15px 5px 15px',
              cursor: 'pointer',
              borderRadius: '55px',
              border: '1px gray',
              backgroundColor: activeGenre === genre.id ? 'gray' : 'transparent',
            }}
          >
            <span style={{ fontSize: "14px", color: activeGenre === genre.id ? 'black' : 'gray' }}> {genre.name?.toUpperCase()}</span>
          </button>
        ))}
      </div>
    );
  };

  const renderItems = () => {
    const filteredItems = watchlistItems.filter(item => {
      if (currentView === 'tv' && item.media_type !== 'tv') return false;
      if (currentView === 'movie' && item.media_type !== 'movie') return false;
      if (activeGenre !== 'All' && !item.genre_ids.split(', ').includes(String(activeGenre))) {
        return false;
      }
      return true;
    });
    return filteredItems.map(item => renderWatchlistItem(item));
  };

  const renderWatchlistItem = (item) => {
    const bookmarkIconClass = isBookmarked(item.id) ? 'fa-bookmark' : 'fa-bookmark-o';

    const handleMouseEnter = async () => {
      if (!providerData[item.id]) {
        setIsFetchingProviders({ ...isFetchingProviders, [item.id]: true });
        await fetchProviders(item, isFetchingProviders, setIsFetchingProviders, setProviderData, fetchAPI);
      }
    };

    let providerIcons = (providerData[item.id] || item.providers?.split(',').map((provider) => provider.trim().replace(/'/g, '')) || [])
      .map((providerName) => {
        const imageName = providerName.toLowerCase().replace(/\s+/g, '-');
        const imagePath = `/static/img/providers/${imageName}.jpeg`;
        return (
          <img key={providerName} src={imagePath} alt={providerName} title={providerName} className="provider-icon" />
        );
      });

    const providerSpinner = isFetchingProviders[item.id] ? (
      <i className="fas fa-spinner fa-spin" style={{ position: 'absolute', bottom: '15px', right: '15px', color: 'white' }}></i>
    ) : null;

    return (
      <div key={item.id} className={`watchlist-item ${undoAvailable[item.id] ? 'undo-available' : ''}`} style={{ position: 'relative' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setHoveredItemId(null)}
        onClick={(event) => handleItemClick(event, item)}>
        <div className="watchlist-item-overlay ">
          {providerSpinner}
          <div style={{ position: 'absolute', bottom: '15px', right: '10px', display: 'flex', flexWrap: 'wrap' }}>
            {providerIcons}
          </div>          
          <RatingBadge voteAverage={item.vote_average} voteCount={item.vote_count} />          
        </div>
        <img
          src={`https://image.tmdb.org/t/p/w500/${item.poster_path}`}
          alt={item.title}
          className={`watchlist-item-image`}
        />
      </div>
    );
  };

  const fetchWatchlistItems = async (username) => {
    setIsLoading(true);
    let data = {};
    let error = null;
    try {
      [data, error] = await fetchAPI(`/api/watchlist/${username}`);
      if (error) throw error;
      setWatchlistItems(data);
      localStorage.setItem(`watchlistItems-${username}`, JSON.stringify(data));
    } catch (error) {
      console.error('Error fetching watchlist:', error);
    } finally {
      setIsLoading(false);
    }

    if (data.name) {
      setWatchlistName(data.name);
      let shortname = data.name.split(":")[0];
      if (shortname.split(" ").length > 2) {
        shortname = shortname.split(" ").slice(0, 2).join(" ");
      }
      Cookies.set("watchlistName", data.name, { expires: 30 });
      Cookies.set("watchlistShortName", shortname, { expires: 30 });
    } else {
      setWatchlistName('Your Watchlist: Share this with family and friends');
    }

    const [allItems, updatedStatuses] = processAndSaveWatchlistData(data, itemStatuses);
    let sortedItems = sortItems(allItems, sortCriteria);
    setWatchlistItems(sortedItems);
    setItemStatuses(updatedStatuses);
    setIsLoading(false);
  };

  const renderWatchlistNameOrButton = () => {
    if (isNameLoading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <i className="fas fa-spinner fa-spin" style={{ fontSize: '20px', color: 'white' }}></i>
          <p style={{ color: 'white' }}>Generating...</p>
        </div>
      );
    }
    if (!isEditingName) {
      return (
        <div style={{ paddingTop: "20px", marginBottom: "25px", display: 'flex', alignItems: 'center' }}>
          <i
            className="fa fa-share-square"
            style={{ fontSize: '26px', color: 'lightgray', cursor: 'pointer', marginLeft: "0px", marginRight: '5px', position: "relative", bottom: "0px" }}
            onClick={copyToClipboard}
          ></i>
          <span
            style={{ fontSize: "22px", color: "lightgray", cursor: 'pointer' }}
            onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
            onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
            onClick={copyToClipboard}
          > Share this page for {watchlistName}
          </span>
          <span
            onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
            onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
            style={{ fontSize: "12px", position: "relative", top: "3px", marginLeft: "10px" }} onClick={() => {
              setIsEditingName(true);
              setTempWatchlistName(watchlistName);
            }}>(EDIT NAME)</span>
        </div>
      );
    } else {
      return (
        <input
          type="text"
          value={tempWatchlistName}
          onChange={handleWatchlistNameChange}
          onKeyDown={handleWatchlistNameSave}
          style={{ fontFamily: 'Times New Roman', fontSize: '24px', color: 'black', width: '100%' }}
          autoFocus
        />
      );
    }
  };

  const handleItemClick = (event, item) => {
    const url = `/details/${item.tmdb_id}/${encodeURIComponent(item.title || item.name)}/${new Date(item.release_date).getFullYear()}/true?media_type=${item.media_type}`;
    window.location.href = url;
  };

  return (
    <div className="watchlist-page" style={{ backgroundColor: "#191C24", borderRadius: "10px", marginTop: "15px" }}>
      {!isLoading && (
        watchlistItems.length > 0 ? renderWatchlistContent() : renderEmptyMessage()
      )}

      {isLoading && (
        <div className="loading-container" style={{ textAlign: 'center', marginTop: '20px' }}>
          <p style={{ color: 'white' }}>LOADING...</p>
          <i className="fas fa-spinner fa-spin" style={{ fontSize: '24px', color: 'white' }}></i>
        </div>
      )}
    </div>
  );
};

export default Watchlist;
