import React, { useState, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';  // Import useSearchParams
import { NavLink } from 'react-router-dom';
import './PersonaMatch.css';
import './Cast.css';
import './MediaGrid.css';
import './Watchlist.css';
import PersonaGrid from './PersonaGrid';

const PersonaMatch = () => {
  const [searchParams] = useSearchParams();
  const [MBTI, setMBTI] = useState(localStorage.getItem('MBTI') || '    ');
  const [showMBTIHelp, setShowMBTIHelp] = useState(false);
  const [selectedMBTIOptions, setSelectedMBTIOptions] = useState({ I: false, E: false, S: false, N: false, T: false, F: false, J: false, P: false });
  const mbtiRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [characterParam, setCharacterParam] = useState(null);
  const [charPic, setCharPic] = useState(null);
  const MBTIType = MBTI.replace(/\s+/g, '');  // Ensure there are no spaces in the MBTI string
  const MBTINames = {
    'INTJ': 'The Architect',
    'INTP': 'The Logician',
    'ENTJ': 'The Commander',
    'ENTP': 'The Debater',
    'INFJ': 'The Advocate',
    'INFP': 'The Mediator',
    'ENFJ': 'The Protagonist',
    'ENFP': 'The Campaigner',
    'ISTJ': 'The Logistician',
    'ISFJ': 'The Defender',
    'ESTJ': 'The Executive',
    'ESFJ': 'The Consul',
    'ISTP': 'The Virtuoso',
    'ISFP': 'The Adventurer',
    'ESTP': 'The Entrepreneur',
    'ESFP': 'The Entertainer'
  };


  useEffect(() => {
    // Load initial data from localStorage or set defaults
    const storedMBTI = localStorage.getItem('MBTI') || '    ';
    // Set the title of the window to "Persona Match - [MBTI]"
    document.title = `Persona Match - ${MBTINames[storedMBTI] || 'MBTI'}`;
    const param_MBTI = searchParams.get('mbti') || MBTI;  // Fallback to a default value or use a prop
    const _MBTIType = param_MBTI.replace(/\s+/g, '').toUpperCase(); // Ensure there are no spaces in the MBTI string
    setCharacterParam(searchParams.get('character'))
    setCharPic(searchParams.get('charPic'))
    
    if (!storedMBTI) setShowMBTIHelp(true);

    setMBTI(_MBTIType);
  }, []);



  const handleMBTIChange = (index, e, fromInput = true) => {
    const value = e.target.value.toUpperCase();

    if (e.nativeEvent && e.nativeEvent.inputType === "deleteContentBackward") {
      let newMBTI = MBTI.split('');
      newMBTI[index] = ' ';
      setMBTI(newMBTI.join(''));
      localStorage.setItem('MBTI', newMBTI.join(''));
    } else if (value.match(/[IENSFTPJ]/)) {
      let newMBTI = MBTI.split('');
      newMBTI[index] = value;
      setMBTI(newMBTI.join(''));
      localStorage.setItem('MBTI', newMBTI.join(''));
      if (fromInput && index < 3) mbtiRefs[index + 1].current.focus();
      if (!fromInput) toggleMBTISelection(value);
    }
  };

  const handleMBTIButtonClick = (index, optionKey) => {
    const newMBTI = MBTI.split('');
    newMBTI[index] = optionKey;
    setMBTI(newMBTI.join(''));
    localStorage.setItem('MBTI', newMBTI.join(''));
    setSelectedMBTIOptions((prevSelected) => {
      const resetSelected = Object.keys(prevSelected).reduce((acc, key) => ({ ...acc, [key]: false }), {});
      return { ...resetSelected, [optionKey]: true };
    });
  };

  const toggleMBTISelection = (key) => {
    setSelectedMBTIOptions((prevSelected) => ({
      ...prevSelected,
      [key]: !prevSelected[key],
    }));
  };

  const MBTIQuestions = [
    {
      question: "Do you feel more energized by being alone - or with others?",
      options: [
        { key: 'I', text: "Alone" },
        { key: 'E', text: "Around Others" }
      ]
    },
    {
      question: "When learning something new, do you prefer facts and details - or the big picture?",
      options: [
        { key: 'S', text: "Facts" },
        { key: 'N', text: "Big Picture" }
      ]
    },
    {
      question: "When making decisions, do you prioritize logic and consistency or people and emotions?",
      options: [
        { key: 'T', text: "Logic" },
        { key: 'F', text: "People" }
      ]
    },
    {
      question: "Do you prefer to have things planned or to stay open and flexible?",
      options: [
        { key: 'J', text: "Planned" },
        { key: 'P', text: "Flexible" }
      ]
    }
  ];

  return (
    <>

      <div className="persona-match-container">

        <div style={{ position: "relative", left: "5px" }}>


          {characterParam && (
            <>
              <img src={`https://image.tmdb.org/t/p/w300/${charPic}`} alt="character" style={{ width: "60px", borderRadius: "10px", float:"left" }} />
              <h3 style={{ marginLeft: "68px",  position: "relative" }}>Similar Characters to <span>{characterParam}</span></h3>
              {MBTI.split('').map((char, index) => (
            <input
              key={index}
              ref={mbtiRefs[index]}
              type="text"
              value={char.trim()}
              onChange={(e) => handleMBTIChange(index, e, true)}
              maxLength="1"
              className="mbti-input"
              placeholder="_"
            />
          ))}
          <span className="mbti-input" style={{ border: "none" }}>{MBTINames[MBTIType]?.toUpperCase()}</span>
            </>

          )}
          {!characterParam && (
            <>
            <div className="">
            <h5>RECOMMENDATIONS BASED ON <span onClick={() => setShowMBTIHelp(!showMBTIHelp)} ><u>PERSONALITY</u></span> </h5>
          {MBTI.split('').map((char, index) => (
            <input
              key={index}
              ref={mbtiRefs[index]}
              type="text"
              value={char.trim()}
              onChange={(e) => handleMBTIChange(index, e, true)}
              maxLength="1"
              className="mbti-input"
              placeholder="_"
            />
          ))}
          <span style={{ color: "#61dafb", paddingLeft:"10px", border: "none", fontWeight:"bold" }}>{MBTINames[MBTIType]?.toUpperCase()}</span>
        </div>
        </>
            
          )}
        </div>
        
        <div className="question-section">
          {showMBTIHelp && (
            <div className="mbti-help">
              {MBTIQuestions.map((q, i) => (
                <div key={i} className='mbti-question'>
                  <div>#{i + 1}. {q.question}</div>
                  {q.options.map((option) => (
                    <button
                      style={{ width: "220px", marginRight: "15px" }}
                      key={option.key}
                      onClick={() => handleMBTIButtonClick(i, option.key)}
                      className={`${selectedMBTIOptions[option.key] ? 'mbti-selected' : 'mbti-not-selected'} question-${i} ${option.key}`}
                    >
                      {option.text?.toUpperCase()}
                    </button>
                  ))}

                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="" style={{ marginLeft: "10px" }}>
        <PersonaGrid MBTI={MBTI}/>

      </div>

    </>
  );
};

export default PersonaMatch;
