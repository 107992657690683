import React, { useEffect, useState } from 'react';
import './EpisodeDetails.css';

const EpisodeDetails = ({ item, lastAiredDate, nextEpisodeToAir, numberOfSeasons }) => {
  const [episodeGuides, setEpisodeGuides] = useState({});
  const [seasonEpisodeMap, setSeasonEpisodeMap] = useState({});
  const itemId = item?.tmdb_id; // Using `tmdb_id` since that's what your fetch depends on
  const [providers, setProviders] = useState([]);

  // Initialize or update the component state based on local storage or defaults
  useEffect(() => {
    const savedSeason = localStorage.getItem(`selectedSeason-${itemId}`) || '1';
    const savedEpisode = localStorage.getItem(`selectedEpisode-${itemId}`) || '1';
    setSeasonEpisodeMap({ [itemId]: { season: savedSeason, episode: savedEpisode } });
    fetchEpisodeGuide(itemId, savedSeason, savedEpisode);

    const providerNames = item.providers ? item.providers.split(',').map(provider => provider.trim().replace(/'/g, '')) : [];
    setProviders(providerNames.filter(name => name && name.toLowerCase() !== 'none')); // Filter out empty or 'none' values
    console.log("numberOfSeasons = ", numberOfSeasons);
  }, [itemId]);

  // Fetch episodes data and update both episodes and selected episode if it's a new season fetch
  const fetchEpisodeGuide = async (tmdbId, season, episode = '1') => {
    try {
      const episodesUrl = `/api/season/${season}/${tmdbId}`;
      const response = await fetch(episodesUrl);
      const data = await response.json();
      // Update number of seasons if it's different from the current value
      if (data.number_of_seasons !== numberOfSeasons) {
        numberOfSeasons = data.number_of_seasons;
      }
      console.log("data = ", data);
      console.log("data.number_of_seasons = ", data.number_of_seasons);

      setEpisodeGuides(prev => ({ ...prev, [`${tmdbId}-S${season}`]: data.episodes }));
      if (season !== seasonEpisodeMap[tmdbId]?.season || episode !== seasonEpisodeMap[tmdbId]?.episode) {
        setSeasonEpisodeMap(prev => ({ ...prev, [tmdbId]: { season, episode } }));
      }
    } catch (error) {
      console.error('Error fetching episodes:', error);
    }
  };

  // Handle season changes and reset the episode to '1'
  const handleSeasonChange = (event) => {
    const newSeason = event.target.value;
    localStorage.setItem(`selectedSeason-${itemId}`, newSeason);
    localStorage.setItem(`selectedEpisode-${itemId}`, '1');
    setSeasonEpisodeMap(prev => ({ ...prev, [itemId]: { season: newSeason, episode: '1' } }));
    fetchEpisodeGuide(itemId, newSeason, '1');
  };

  // Handle episode changes without fetching new data
  const changeEpisode = (newEpisode) => {
    localStorage.setItem(`selectedEpisode-${itemId}`, newEpisode);
    setSeasonEpisodeMap(prev => ({ ...prev, [itemId]: { ...prev[itemId], episode: newEpisode } }));
  };

  const incrementEpisode = () => {
    const episodes = episodeGuides[`${itemId}-S${currentSeason}`] || [];
    if (Number(currentEpisode) < episodes.length) {
      changeEpisode(String(Number(currentEpisode) + 1));
    }
  };

  const decrementEpisode = () => {
    if (Number(currentEpisode) > 1) {
      changeEpisode(String(Number(currentEpisode) - 1));
    }
  };

  const currentSeason = seasonEpisodeMap[itemId]?.season || '1';
  const currentEpisode = seasonEpisodeMap[itemId]?.episode || '1';
  const episodeDetails = episodeGuides[`${itemId}-S${currentSeason}`]?.find(ep => ep.episode_number.toString() === currentEpisode);

  return (
    <div>
      {episodeDetails ? (
        <div>
          <div style={{ fontSize: "22px", color: "white" }}>
            <i style={{ paddingRight: "5px" }} className="fa fa-calendar" aria-hidden="true"></i>
            &nbsp;SEASON
            <select value={currentSeason} onChange={handleSeasonChange} style={{ marginLeft: "10px", marginRight: "10px" }}>
              {Array.from({ length: numberOfSeasons }, (_, index) => (
                <option key={index + 1} value={index + 1}>{index + 1}</option>
              ))}
            </select>
            EPISODE <button onClick={decrementEpisode} style={{ backgroundColor: "black", color: "gray", border: "none", paddingTop: "5px", fontSize: "22px" }}><i className="fa fa-arrow-left"></i></button> #{currentEpisode}<button onClick={incrementEpisode} style={{ backgroundColor: "black", color: "gray", border: "none", fontSize: "22px" }}><i className='fa fa-arrow-right'></i></button> {episodeDetails.name}
          </div>
          <div className="episode-detail-overlay">
            {episodeDetails.still_path === null && (
              <div className="episode-still-image" style={{ backgroundColor: "black", color: "gray", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <i className="fa fa-image" style={{ fontSize: "150px" }}></i>
              </div>
            )}
            {episodeDetails.still_path != null && (
              <img src={`https://image.tmdb.org/t/p/w500${episodeDetails.still_path}`} alt={`Still from "${episodeDetails.name}"`} style={{ paddingTop: "5px" }} className="episode-still-image" />
            )}
            <div className="episode-overview-not" >
              <p style={{ fontSize: "16px" }}>{episodeDetails.overview}</p>
            </div>
          </div>
        </div>
      ) : (
        <div>Loading episode details...</div>
      )}
    </div>
  );
};

export default EpisodeDetails;
