const RatingBadge = ({ voteAverage, voteCount, isOverlay=true }) => {

  let ratingPercent = Math.round(voteAverage * 10);
  const isHighScore = voteAverage >= 8 && voteCount > 10;
  const isLowScore = voteAverage < 6 && voteCount > 10;
  const badgeColor = isHighScore ? 'lightyellow' : 'lightgray';
  let iconClass = isHighScore ? 'fa fa-thumbs-up' : 'fa fa-meh-o';
  if (isLowScore) iconClass = 'fa fa-thumbs-down';
  ratingPercent += "%";
  if (voteCount < 10) ratingPercent = ""
  let top="0%";
  let left="0%";
  let position = "relative";
  if (isOverlay) {
    top = "50%";
    left = "50%";
    position = "absolute";
  }
  return (
    isOverlay ? (
      <div style={{
        position: `${position}`,
        top: `${top}`,
        left: `${left}`,
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2
      }}>
        <i className={`${iconClass}`} style={{ color: badgeColor, fontSize: "60px" }}></i>{ratingPercent}
        <span style={{
          color: badgeColor,
          fontSize: '22px',
          fontWeight: 'bold'
        }}>
          
        </span>
      </div>
    ) : (
      <span style={{ 
        borderRadius: '5px',
        fontSize: '16px',
        fontWeight: 'normal',
        marginRight: '5px'
      }}>  <i className={`${iconClass}`} style={{ marginRight: '5px', fontSize: "32px"  }}></i> {ratingPercent} USER RATING
      </span>
    )
  );
};

export default RatingBadge;