import React, { useState, useEffect } from 'react';
import './SearchResults.css'; // Make sure this CSS includes styles for your search results
import { addToWatchlist } from './utils'; // Adjust the import path based on your file structure
import DetailsPage from './DetailsPage';
import RatingBadge from './RatingBadge';
import { useToast } from './ToastContext';

const SearchResults = ({ results, onRecommendationsFetched, fetchRecommendations }) => {
  const [currentResults, setCurrentResults] = useState(results);
  const [detailsOverlayVisible, setDetailsOverlayVisible] = useState(false);
  const [selectedTmdbid, setSelectedTmdbid] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedReleaseYear, setSelectedReleaseYear] = useState("");
  const { showToast } = useToast();

  useEffect(() => {
    setCurrentResults(results); // Update results when the incoming prop changes
  }, [results]);

  const handleImageClick = (item) => {
    console.log('handleImageClick', item);
    const _date = item.release_date || item.first_air_date;
    const url = `/details/${item.id}/${encodeURIComponent(item.title || item.name)}/${new Date(_date).getFullYear()}/true?media_type=${item.media_type}`;
    window.open(url, '_blank');
  };

  // Check if length will fail for current results, and return null if so
  if (!currentResults) {
    return null;
  }
  return (
    <>
      <div className={`media-grid ${currentResults.length ? '' : 'hide'}`}>
        {currentResults
          .filter(result => result.poster_path) // Only render results with a valid image
          .map((result) => (
            <div key={result.id} className="media-item animate__animated animate__zoomIn">
              <div className="image-container" onClick={() => handleImageClick(result)}>
                <img
                  src={`https://image.tmdb.org/t/p/w500/${result.poster_path}`}
                  alt={result.title || result.name}
                />
                <div className="overlay" style={{display:'block'}}>
                  <RatingBadge voteAverage={result.vote_average} voteCount={result.vote_count} />
                  <button onClick={(e) => {
                    e.stopPropagation();
                    addToWatchlist(result, e);
                  }} className="add-watchlist-btn" >
                    <i className="fa fa-plus"></i> Add
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className='stuff-goes-here'>
        <div style={{ postion: "relative" }}>
          {detailsOverlayVisible && (
            <div style={{ marginTop: "10px" }}>
              <DetailsPage showEpisodeDetails={false} showAddButton={true} showCast={false} isCompactView={false} tmdb_id={selectedTmdbid} title={selectedTitle} release_year={selectedReleaseYear} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchResults;
