import React, { useState, useEffect } from 'react';
import { addToWatchlist } from './utils';  // Ensure utilities are properly imported if used
import { useToast } from './ToastContext';  // For showing notifications
import './MediaGrid.css';  // Ensure you have the correct styles
import { useSearchParams } from 'react-router-dom';  // Import useSearchParams

const alignments = {
    laws: ['Lawful', 'Chaotic'],
    morals: ['Good', 'Evil']
};

const PersonaGrid = ({ MBTI, lawAlignment, moralAlignment }) => {
    const { showToast } = useToast();
    const [searchParams] = useSearchParams();
    const [castMembers, setCastMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const MBTIType = MBTI.replace(/\s+/g, '');  // Ensure there are no spaces in the MBTI string

    useEffect(() => {
        fetchStars();
    }, [MBTI, lawAlignment, moralAlignment]);

    const fetchStars = async () => {
        setLoading(true);
        const param_MBTI = searchParams.get('mbti') || MBTI;  // Use MBTI from URL parameter if available
        const _MBTIType = param_MBTI.replace(/\s+/g, '').toUpperCase(); // Clean and prepare the MBTI type
        console.log('MBTI:', param_MBTI);
        console.log('MBTI Type:', _MBTIType);

        let url = `/api/stars?personality=${_MBTIType}`;

        try {
            let response = await fetch(url);
            let data = await response.json();
            // Randomly shuffle the cast members before setting them into the state 
            setCastMembers(data);
            setLoading(false);
            console.log('Fetched and shuffled stars:', data);
        } catch (error) {
            console.error('Fetching stars failed:', error);
            setLoading(false);
        }
    };

    const handleAddToWatchlist = async (castMember, event) => {
        event.stopPropagation(); // Prevent the click from bubbling up
        const item = {
            id: castMember.fields.tmdb_id,
            title: castMember.fields.actor_name,
            media_type: castMember.fields.media_type
        };

        try {
            const response_data = await addToWatchlist(item);
            showToast("Added to watchlist");
            console.log('Added to watchlist:', response_data);
        } catch (error) {
            console.error('Error adding to watchlist:', error);
        }
    };

    const openCastDetailsInNewWindow = (castMember, media) => {
        const url = `/cast-details/${castMember.fields.credit_id}/${castMember.fields.media_type}/${castMember.fields.media_info.title}`;
        window.location.href = url;
    };

    const handleImageClick = (item) => {
        // Navigate to the DetailsPage with URL parameters
        const url = `/details/${item.tmdb_id}/${encodeURIComponent(item.title || item.name)}/${new Date(item.release_date).getFullYear()}/true`;
        window.location.href = url;
    };

    return (
        <div className="cast-grid-wide">
            {loading ? (
                <p>Loading...</p>
            ) : (
                castMembers.map(member => (
                    <div key={member.id} className="character-card">
                        <h4><b>{member.fields.media_info.title}</b> ({new Date(member.fields.media_info.release_date).getFullYear()})</h4>
                        <div style={{ marginRight: "20px", float: "left" }}>
                            {member.fields.media_info.full_backdrop_path && (
                                <img
                                    width="125px"
                                    onClick={() => handleImageClick(member.fields.media_info)}
                                    src={member.fields.media_info.full_backdrop_path}
                                    alt="Movie backdrop"
                                    className="media-backdrop"
                                    style={{ cursor: 'pointer', borderRadius: "10px" }}
                                />
                            )}
                        </div>
                        <div>
                            {member.fields.profile_path && (
                                <img
                                    width="125px"
                                    onClick={() => openCastDetailsInNewWindow(member)}
                                    src={`https://image.tmdb.org/t/p/w300${member.fields.profile_path}`}
                                    alt={member.fields.actor_name}
                                    style={{ cursor: 'pointer', marginRight: "15px", borderRadius: "10px", float: "left" }}
                                />
                            )}
                            <b>Similar Character</b> ({member.fields.personality_profile})<p>{member.fields.character} played by {member.fields.actor_name}</p>
                            <b>Overview</b><p>{member.fields.media_info.overview}</p>
                            <a href="#" style={{ position: "relative", display: "none", left: "10px", color: "gray" }}><u>Learn more</u></a>
                            <br />
                            <span style={{ paddingRight: "10px" }}>
                                <a href={`${member.fields.media_info.url}/watch`} target="_blank" rel="noopener noreferrer" className="btn btn-small btn-primary"><i className="fa fa-play"></i>&nbsp;Play {member.fields.media_type === 'tv' ? 'TV Show' : 'Movie'}</a>
                            </span>
                            <span>
                                <button className="btn btn-small btn-secondary" onClick={(e) => handleAddToWatchlist(member, e)}><i className="fa fa-plus"></i> Watchlist</button>
                            </span>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default PersonaGrid;
