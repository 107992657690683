import React from 'react';
import { useToast } from './ToastContext';
import './Toast.css';

const Toast = () => {
  const { toast, hideToast } = useToast(); // Make sure you have a hideToast method in your context

  return (
    toast.show && (
      <div className="toasty">
        {toast.message}
        <button onClick={hideToast} className="toast-close-btn">
          <i style={{fontSize:"20px"}} className="fa fa-times"></i>
        </button>
      </div>
    )
  );
};

export default Toast;
