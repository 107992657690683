import React, { useState, useEffect } from 'react';
import './DetailsPage.css';
import RatingBadge from './RatingBadge';
import EpisodeDetails from './EpisodeDetails';
import Cast from './Cast';
import './Cast.css';
import './CastDetails.css';
import { addToWatchlist } from './utils';
import { useParams, useSearchParams} from 'react-router-dom';  // Import useParams

const DetailsPage = () => {
  const { tmdb_id, title, release_year, showAddButton } = useParams();
  const [searchParams] = useSearchParams();
  const [mediaDetails, setMediaDetails] = useState(null);
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastAiredDate, setLastAiredDate] = useState('');
  const [mediaType, setMediaType] = useState('');
  const [nextEpisodeToAir, setNextEpisodeToAir] = useState('');
  const [numberOfSeasons, setNumberOfSeasons] = useState('');
  const [showCast, setShowCast] = useState(true);
  const [recommendations, setRecommendations] = useState([]);
  const [glossaryEntries, setGlossaryEntries] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  useEffect(() => {
    setMediaType(searchParams.get('media_type'))
    // console.log('searchParams = ', searchParams.get('media_type'));
    // console.log('mediaType = ', mediaType);
}, []);
  useEffect(() => {
    const parseGlossary = (glossary) => {
      const regex = /TERM: (.*?)\nDEFINITION: (.*?)(?=\nTERM: |\n$)/gs;
      let match;
      const entries = [];
      while ((match = regex.exec(glossary)) !== null) {
        entries.push({ term: match[1].trim(), definition: match[2].trim() });
      }
      return entries;
    };

    if (mediaDetails && mediaDetails.glossary) {
      const entries = parseGlossary(mediaDetails.glossary);
      setGlossaryEntries(entries);
    }
  }, [mediaDetails]);
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleChatSubmit();
    }
  };

  // Handle chat form submission
  const handleChatSubmit = async () => {
    const chatUrl = `/api/question?title=${encodeURIComponent(title)}&q=${encodeURIComponent(question)}&media_type=${mediaDetails.media_type}`;
    try {
      const response = await fetch(chatUrl);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setAnswer(data.answer);
    } catch (error) {
      console.error('Error fetching chat response:', error);
      setAnswer('Sorry, there was an error processing your question.');
    }
  };


  useEffect(() => {
    document.title = `${title} - ${release_year}`;
    
    const fetchBasicDetails = async () => {
      try {
        const url = `/api/basic/media/${encodeURIComponent(tmdb_id)}?title=${encodeURIComponent(title)}&release_year=${encodeURIComponent(release_year)}`;
        const detailsResponse = await fetch(url);
        const detailsData = await detailsResponse.json();
        setMediaDetails(detailsData);

        if (!detailsData.glossary || !detailsData.providers) {
          const publicUrl = `/api/public/media/${encodeURIComponent(tmdb_id)}?title=${encodeURIComponent(title)}&release_year=${encodeURIComponent(release_year)}`;
          const publicResponse = await fetch(publicUrl);
          const publicData = await publicResponse.json();

          if (!detailsData.glossary && publicData.glossary) {
            detailsData.glossary = publicData.glossary;
          }
          if (!detailsData.providers && publicData.providers) {
            detailsData.providers = publicData.providers;
          }
          setMediaDetails(detailsData);
        }

        const providerNames = detailsData.providers ? detailsData.providers.split(',').map(provider => provider.trim().replace(/'/g, '')) : [];
        setProviders(providerNames.filter(name => name && name.toLowerCase() !== 'none'));
        if (detailsData.media_type === 'tv') {
          const episodesUrl = `/api/episodes/${detailsData.tmdb_id}`;
          const episodesResponse = await fetch(episodesUrl);
          const episodesData = await episodesResponse.json();
          const date = new Date(episodesData.last_air_date);
          const formattedDate = date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ", " + date.getFullYear();
          setLastAiredDate("Last Episode: " + formattedDate + ".");
          
          setNumberOfSeasons(episodesData.number_of_seasons);
          if (episodesData.next_episode_to_air) {
            const nextEpisodeDate = new Date(episodesData.next_episode_to_air);
            const formattedNextEpisodeDate = nextEpisodeDate.toLocaleString('default', { month: 'long' }) + " " + nextEpisodeDate.getDate() + ", " + nextEpisodeDate.getFullYear();
            setNextEpisodeToAir("Next Episode: " + formattedNextEpisodeDate + ".");
          }
        }
      } catch (error) {
        console.error('Error fetching basic details:', error);
      }
    };

    const fetchAdditionalDetails = async () => {
      try {
        const recUrl = `/search_recommendations?id=${tmdb_id}&media_type=${searchParams.get('media_type')}`;
        const recResponse = await fetch(recUrl);
        const recData = await recResponse.json();
        const _results = recData.searchResults.results || [];
        setRecommendations(_results);
        const videoUrl = `/w/videos/${searchParams.get('media_type')}/${tmdb_id}`;
        const videosResponse = await fetch(videoUrl);
        const videosData = await videosResponse.json();
        setRelatedVideos(videosData?.results?.filter(video => video.type === 'Trailer'));
      } catch (error) {
        console.error('Error fetching additional details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBasicDetails().then(() => fetchAdditionalDetails());
  }, [title, release_year, tmdb_id]);


  if (loading) {
    return (
      <div style={{ paddingTop: "20px" }}>
        <div className="card">
          <h4>Loading...</h4>
        </div>
      </div>
    )
  }

  if (!mediaDetails) {
    return (
      <div style={{ paddingTop: "0px" }}>
        <div style={{ paddingTop: "20px", marginTop: "18px" }} className=''>
        <div style={{ fontSize: "22px", paddingLeft: "15px" }} className="cast-detail-title">{title.toUpperCase()}<span style={{ fontSize: "22px", marginLeft: "10px", marginRight: "10px", fontWeight: "normal", color: "white" }}>({release_year})</span>
          </div>
          <div style={{ fontSize: "22px", color: "white", paddingBottom: "5px", paddingTop: "20px" }}>
            <span style={{ marginLeft: "15px", color: "gray" }}>Loading...</span>
          </div>
        </div>
      </div>
    )
  } 

  async function handleAddToWatchlist(item, event) {
    let response_data = await addToWatchlist(item, event);
    // showToast("Added to Watchlist");
  };
  const renderFullView = () => (
    <div style={{ paddingTop: "20px", marginTop: "15px" }} className=''>
      <div style={{ fontSize: "22px", paddingLeft: "15px" }} className="cast-detail-title">{mediaDetails.title?.toUpperCase()}<span style={{ fontSize: "22px", marginLeft: "10px", marginRight: "10px", fontWeight: "normal", color: "white" }}>({new Date(mediaDetails.release_date).getFullYear()})</span>
        {providers.map(provider => (
          <img key={provider} src={`/static/img/providers/${provider.toLowerCase().replace(/ /g, '-')}.jpeg`} alt={provider} style={{ width: "47px", padding: "5px", borderRadius: "10px" }} />
        ))}
        <RatingBadge isOverlay={false} voteAverage={mediaDetails.vote_average} voteCount={mediaDetails.vote_count} />
      </div>

      {mediaDetails.media_type === 'movie' && (
        <div className="cast-details-info">
          <div className="character-main-card" >
            <div className='character-info'>
              {relatedVideos?.length > 0 && (
                <iframe
                  style={{ width: "100%", height: "300px" }}
                  src={`https://www.youtube.com/embed/${relatedVideos[0].key}`}
                  title={relatedVideos[0].name}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
              {(!relatedVideos || relatedVideos?.length === 0) && (
                <img src={`https://image.tmdb.org/t/p/w500/${mediaDetails.backdrop_path}`} alt={mediaDetails.title} style={{ width: "100%", borderRadius: "5px", marginRight: "15px" }} />
              )}
              <div style={{ fontSize: "22px", color: "white", paddingBottom: "5px", paddingTop: "10px" }}>
                <i style={{ paddingRight: "5px", paddingTop: "10px" }} className="fa fa-info-circle" aria-hidden="true"></i> OVERVIEW
                <a href={`${mediaDetails.url}/watch`} target="_blank" rel="noopener noreferrer" style={{ fontSize: "16px", marginLeft: "15px" }} className="play-button">
                  <i className='fa fa-play'></i> PLAY
                </a>
                <button onClick={(e) => handleAddToWatchlist(mediaDetails, e)} className="button-link btn btn-secondary" style={{ marginLeft: "15px", position: "relative", bottom: "10px", paddingBottom: "5px", paddingTop: "5px" }}>
                  + WATCHLIST
                </button>
              </div>

              <p style={{ marginTop: "0px" }}>{mediaDetails.overview}
              </p>
              {/* Chat Section */}
              <div style={{ marginTop: "15px", borderRadius: "10px", color: "white" }}>
                <span className="form-group">
                  <i style={{ paddingRight: "5px", paddingBottom: "5px" }} className="fa fa-commenting-o" aria-hidden="true"></i><label htmlFor="questionInput">Ask a question about {title}</label>
                  <input
                    type="text"
                    style={{backgroundColor:"black", borderColor:"darkgray", color:"lightgray"}}
                    className="form-control"
                    id="questionInput"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </span>
                {answer && (
                  <div style={{ marginTop: "20px", backgroundColor: "#444", padding: "15px", borderRadius: "5px" }}>
                    <h5>Answer:</h5>
                    <p>{answer}</p>
                  </div>
                )}
              </div>
              <br/>
              <div style={{ fontSize: "22px", color: "white" }}>
                <i style={{ paddingRight: "5px", marginLeft: "0px", paddingBottom: "10px" }} className="fa fa-group" aria-hidden="true"></i> MOVIE CHARACTERS
              </div>
              {showCast && (
                <Cast media_type={mediaDetails.media_type} tmdb_id={mediaDetails.tmdb_id} title={mediaDetails.title} />
              )}

            </div>
          </div>
          <div className='character-card'>
            <div className='character-info'>
              <div style={{ fontSize: "22px", color: "white", paddingBottom: "10px" }}>
                <i style={{ paddingRight: "5px", paddingBottom: "10px" }} className="fa fa-film" aria-hidden="true"></i> SIMILAR MOVIES
              </div>
              <div className="recommendations">
                {recommendations?.map((item) => (
                  <span key={item.id} className="recommendation-card" style={{ gap: "10px" }}>
                    <a href={`/details/${item.id}/${encodeURIComponent(item.title || item.name)}/${new Date(item.release_date).getFullYear()}/true?media_type=${item.media_type}`}>
                    <img src={`https://image.tmdb.org/t/p/w500${item.poster_path}`} style={{ borderRadius: "20px", padding: "10px", width: "140px", cursor: "pointer" }} alt={item.title} />
                    </a>
                    
                  </span>
                ))}
              </div>
            </div>


            {mediaDetails && mediaDetails.glossary && (
              <div className="character-info" >
                <div style={{ fontSize: "22px", color: "white", paddingBottom: "10px", paddingTop: "10px" }}>
                  <i style={{ paddingRight: "5px", paddingBottom: "5px" }} className="fa fa-edit" aria-hidden="true"></i> GLOSSARY
                </div>
                {glossaryEntries.map((entry, index) => (
                  <div key={index} className="glossary-entry">
                    <strong>{entry.term}:</strong> {entry.definition}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}



      {mediaDetails.media_type === 'tv' && (
        <div className="cast-details-info" >
          <div className="character-main-card" >
            <div className='character-info'>
              {relatedVideos?.length > 0 && (
                <iframe
                  style={{ width: "100%", height: "300px" }}
                  src={`https://www.youtube.com/embed/${relatedVideos[0].key}`}
                  title={relatedVideos[0].name}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
              {(!relatedVideos || relatedVideos?.length === 0) && (
                <img src={`https://image.tmdb.org/t/p/w500/${mediaDetails.backdrop_path}`} alt={mediaDetails.title} style={{ width: "100%", borderRadius: "5px", marginRight: "15px" }} />
              )}



              <div style={{ fontSize: "22px", color: "white", paddingBottom: "5px", paddingTop: "10px" }}>
                <i style={{ paddingRight: "5px", paddingTop: "10px" }} className="fa fa-info-circle" aria-hidden="true"></i> OVERVIEW
                <a href={`${mediaDetails.url}/watch`} target="_blank" rel="noopener noreferrer" style={{ fontSize: "16px", marginLeft: "15px" }} className="play-button">
                  <i className='fa fa-play'></i> PLAY
                </a>
                <button onClick={(e) => handleAddToWatchlist(mediaDetails, e)} className="button-link btn btn-secondary" style={{ marginLeft: "15px", position: "relative", bottom: "10px", paddingBottom: "5px", paddingTop: "5px" }}>
                  + WATCHLIST
                </button>
              </div>
              <p style={{ marginTop: "0px" }}>
                <b>{lastAiredDate} {nextEpisodeToAir}</b><br/>
                {mediaDetails.overview}
              </p>
              
              {/* Chat Section */}
              <div style={{  marginTop: "15px",  borderRadius: "10px", color: "white" }}>

                <span className="form-group">
                  <i style={{ paddingRight: "5px", paddingBottom: "5px" }} className="fa fa-commenting-o" aria-hidden="true"></i><label htmlFor="questionInput">Ask a question about {title}</label>
                  <input
                    type="text"
                    style={{backgroundColor:"black", borderColor:"darkgray", color:"lightgray"}}
                    className="form-control"
                    id="questionInput"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </span>
                {answer && (
                  <div style={{ marginTop: "20px", backgroundColor: "#444", padding: "15px", borderRadius: "5px" }}>
                    <h5>Answer:</h5>
                    <p>{answer}</p>
                  </div>
                )}
              </div>
              <br/>
              <EpisodeDetails
                item={mediaDetails}
                lastAiredDate={lastAiredDate}
                nextEpisodeToAir={nextEpisodeToAir}
                numberOfSeasons={numberOfSeasons}
              />

              {mediaDetails && mediaDetails.glossary && (
                <div className="character-info" >
                  <div style={{ fontSize: "22px", color: "white", paddingBottom: "10px", paddingTop: "10px" }}>
                    <i style={{ paddingRight: "5px", paddingBottom: "5px" }} className="fa fa-edit" aria-hidden="true"></i> GLOSSARY
                  </div>
                  {glossaryEntries.map((entry, index) => (
                    <div key={index} className="glossary-entry">
                      <strong>{entry.term}:</strong> {entry.definition}
                    </div>
                  ))}
                </div>
              )}
              <br />

            </div>
          </div>
          <div className='character-card'>
            <div className='character-info'>
              <div style={{ fontSize: "22px", color: "white", paddingBottom: "10px" }}>
                <i style={{ paddingRight: "5px", paddingBottom: "5px" }} className="fa fa-tv" aria-hidden="true"></i> SIMILAR SHOWS
              </div>

              <div className="recommendations">
                {recommendations.map((item) => (
                  <span key={item.id} className="recommendation-card" style={{ gap: "10px" }}>
                    <a href={`/details/${item.id}/${encodeURIComponent(item.title || item.name)}/${new Date(item.release_date).getFullYear()}/true?media_type=${item.media_type}`}>
                    <img src={`https://image.tmdb.org/t/p/w500${item.poster_path}`} style={{ borderRadius: "20px", padding: "10px", width: "140px", cursor: "pointer" }} alt={item.title} />
                    </a>
                  </span>
                ))}
              </div>
            </div>
            <br />
            <div style={{ position: "relative" }}>
              <div style={{ fontSize: "22px", color: "white" }}>
                <i style={{ paddingRight: "5px", marginLeft: "17px", paddingBottom: "10px" }} className="fa fa-group" aria-hidden="true"></i> TV CHARACTERS
              </div>
              {showCast && (
                <Cast media_type={mediaDetails.media_type} tmdb_id={mediaDetails.tmdb_id} title={mediaDetails.title?.toUpperCase()} />
              )}
            </div >
          </div>
        </div>
      )}
    </div>
  );


  return renderFullView();
};
export default DetailsPage;