// ToastContext.js
import React, { createContext, useState, useContext } from 'react';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({ message: '', show: false });

  
  const showToast = (message) => {
    setToast({ message, show: true });
    // setTimeout(() => setToast({ message: '', show: false }), 25000);
  };
  const hideToast = () => {
    setToast({ message: '', show: false });
  };
  
  return (
    <ToastContext.Provider value={{ toast, showToast, hideToast }}>
    {children}
    </ToastContext.Provider>
  );
};
