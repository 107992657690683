import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Cast.css';
import './CastDetails.css';

const CastDetails = () => {
  const { credit_id, media_type, title } = useParams();
  const [castDetails, setCastDetails] = useState(null);
  const [mediaDetails, setMediaDetails] = useState(null);
  const [mediaForPerson, setMediaForPerson] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMBTI, setSelectedMBTI] = useState(localStorage.getItem('MBTI') || ''); // Assuming the user's MBTI type is stored in localStorage
  const MBTINames = {
    'INTJ': 'INTJ: The Architect',
    'INTP': 'INTP: The Logician',
    'ENTJ': 'ENTJ: The Commander',
    'ENTP': 'ENTP: The Debater',
    'INFJ': 'INFJ: The Advocate',
    'INFP': 'INFP: The Mediator',
    'ENFJ': 'ENFJ: The Protagonist',
    'ENFP': 'ENFP: The Campaigner',
    'ISTJ': 'ISTJ: The Logistician',
    'ISFJ': 'ISFJ: The Defender',
    'ESTJ': 'ESTJ: The Executive',
    'ESFJ': 'ESFJ: The Consul',
    'ISTP': 'ISTP: The Virtuoso',
    'ISFP': 'ISFP: The Adventurer',
    'ESTP': 'ESTP: The Entrepreneur',
    'ESFP': 'ESFP: The Entertainer'
  };

  useEffect(() => {
    const fetchCastDetails = async () => {
      try {
        // Fetch cast details
        const response = await fetch(`/api/cast/details/${credit_id}/${media_type}/${title}`);
        if (!response.ok) throw new Error('Failed to fetch cast details');
        const data = await response.json();
        let personalitySections = {};
        personalitySections = parsePersonalityDescription(data.personality_description);
        setCastDetails({ ...data, ...personalitySections });

        // Once cast details are fetched, use the actor_id to fetch media list
        if (data.actor_id) {
          const mediaResponse = await fetch(`/api/person/media/${data.actor_id}`);
          const mediaData = await mediaResponse.json();
          setMediaForPerson(mediaData);
        }


        document.title = `${data.character} - ${title}`;
        // Fetch media details using tmdb_id from cast details
        if (data.tmdb_id) {
          const mediaResponse = await fetch(`/api/public/media/${data.tmdb_id}?media_type=${media_type}&title=${title}`);
          const mediaData = await mediaResponse.json();
          setMediaDetails(mediaData);
        }
      } catch (err) {
        setError('Failed to fetch cast details');
        console.error('Error fetching cast details:', err);
      } finally {
        setLoading(false);
      }
    };
    // Set the title of the window to the cast name
    fetchCastDetails();
  }, [credit_id, media_type, title]);

  const findSimilarCharacters = (mbti) => {
    const url = `/persona-match?mbti=${castDetails?.personality_profile}&charPic=${castDetails?.profile_path}&character=${castDetails?.character}`;
    window.location.href = url;
  };
  const loadPage = (url) => {
    window.location.href = url;
  };
  const parsePersonalityDescription = (description) => {
    const headers = ["Age", "Occupation", "Short Bio", "Key Traits", "Pivotal Moments", "Quotes", "Viewer Connection", "Character Journey"];
    let sections = {
      shortBio: '',
      keyTraits: '',
      pivotalMoments: '',
      quotes: '',
      viewerConnection: '',
      characterJourney: ''
    };

    let content = description;

    headers.forEach((header, index) => {
      let nextHeader = headers[index + 1] || null;
      let start = content.indexOf(`**${header}:**`) + header.length + 4; // finds the header and moves past it
      let end = nextHeader ? content.indexOf(`**${nextHeader}:**`) : content.length; // finds the next header or goes to the end of content

      let sectionContent = content.substring(start, end).trim();
      // Cleans up the content by removing excess new lines, dash characters, and asterisks
      sectionContent = sectionContent.replace(/^\s*[-*\n]+/gm, '').trim();
      console.log(header);
      console.log(sectionContent);
      // Map the header to the appropriate key in sections object
      switch (header) {
        case "Age":
          sections.age = sectionContent;
          break;
        case "Occupation":
          sections.occupation = sectionContent;
          break;
        case "Short Bio":
          sections.shortBio = sectionContent;
          break;
        case "Key Traits":
          sections.keyTraits = sectionContent;
          break;
        case "Pivotal Moments":
          sections.pivotalMoments = sectionContent;
          break;
        case "Quotes":
          sections.quotes = sectionContent;
          break;
        case "Viewer Connection":
          sections.viewerConnection = sectionContent;
          break;
        case "Character Journey":
          sections.characterJourney = sectionContent;
          break;
        default:
          break; // Handles any unexpected case
      }
    });
    console.log(sections);
    return sections;
  };

  if (loading) return <div className="cast-details-page" style={{ backgroundColor: "#191C24", borderRadius: "10px", marginTop: "15px", paddingTop: "15px" }}>LOADING...</div>;
  if (error) return <div className="cast-details-page" style={{ backgroundColor: "#191C24", borderRadius: "10px", marginTop: "15px", paddingTop: "15px" }}>{error}</div>;

  const handleImageClick = (item) => {
    // Navigate to the DetailsPage with URL parameters
    const url = `/details/${item.tmdb_id}/${encodeURIComponent(item.title || item.name)}/${new Date(item.release_date).getFullYear()}/true?media_type=${item.media_type}`;
    window.location.href = url;
  };

  const handleMediaClick = (item) => {
    const _date = item.release_date || item.first_air_date;
    const url = `/details/${item.id}/${encodeURIComponent(item.title || item.name)}/${new Date(_date).getFullYear()}/true?media_type=${item.media_type} `;
    window.location.href = url;
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <div className="" style={{ paddingTop: "20px" }}>
        <div style={{ fontSize: "22px", paddingLeft: "15px" }} className="cast-detail-title">{mediaDetails.title?.toUpperCase()}<span style={{ fontSize: "22px", marginLeft: "10px", marginRight: "10px", fontWeight: "normal", color: "white" }}>({new Date(mediaDetails.release_date).getFullYear()})</span>
        </div>

        <div className="cast-details-info">
          <div className="character-main-card">
            <div className="character-info">
              <img src={`https://image.tmdb.org/t/p/w300/${castDetails?.profile_path}`} alt={castDetails?.actor_name} className="cast-profile-image" />
              <a href={`/details/${mediaDetails.id}/${encodeURIComponent(mediaDetails.title || mediaDetails.name)}/${new Date(mediaDetails.release_date || mediaDetails.first_air_date).getFullYear()}/true?media_type=${mediaDetails.media_type}`} style={{ textDecoration: "none" }}>
                <img src={mediaDetails?.full_poster_path} alt="Poster" className="cast-profile-image" style={{ cursor: "pointer" }} />
              </a>
              <div style={{ fontSize: "22px", color: "white", paddingBottom: "5px", paddingTop: "10px" }}>
                <button onClick={() => loadPage(`https://www.themoviedb.org/person/${castDetails.actor_id}`)} className="button-link btn btn-primary" style={{ position: "relative", marginLeft: "0px", bottom: "12px" }}>
                  {castDetails.actor_name} Bio
                </button>
                <button onClick={() => findSimilarCharacters(selectedMBTI)} className="button-link btn btn-secondary" style={{ marginLeft: "15px", position: "relative", bottom: "12px" }}>
                  Similar Characters
                </button>

              </div>
              <i style={{ paddingRight: "5px" }} className="fa fa-user-circle-o" aria-hidden="true"></i> {castDetails?.character?.toUpperCase() || ''}
              <p style={{ paddingTop: "5px" }}>{castDetails?.shortBio}</p>
              <div style={{ fontSize: "22px", color: "white", paddingBottom: "5px" }}><i style={{ paddingRight: "5px" }} className="fa fa-quote-left" aria-hidden="true"></i> NOTABLE QUOTES</div>
              <ul>
                {castDetails?.quotes ? castDetails.quotes.split('\n').filter(quote => quote.trim() !== '').map((quote, index) => (
                  <li key={index}>{quote.trim().replace(/^\d+\.\s*/, '')}</li> // Remove leading numbers
                )) : <li>Quotes not available.</li>}
              </ul>
              <div style={{ fontSize: "22px", color: "white", paddingBottom: "5px" }}><i style={{ paddingRight: "5px" }} className="fa fa-line-chart fa-psychology" aria-hidden="true"></i> PSYCHOLOCIAL PROFILE</div>
              {selectedMBTI === castDetails?.personality_profile ? <i style={{ color: "gold", fontSize: "17px", marginRight: "5px" }} className='fa fa-star'></i> : null}{MBTINames[castDetails?.personality_profile] || "Personality Type Not Available"}, {castDetails?.character_alignment || "-"}
              <ul>{castDetails?.keyTraits ? castDetails.keyTraits.split('\n').filter(trait => trait.trim() !== '').map((trait, index) => (
                <i><span key={index}>{trait.trim()}, </span></i>
              )) : <b>Key traits not available.</b>}</ul>

              <div style={{ fontSize: "22px", color: "white", paddingBottom: "5px" }}>
                <i style={{ paddingRight: "5px" }} className="fa fa-hourglass-half" aria-hidden="true"></i> APPROXIMATE AGE</div>
              <p style={{ paddingTop: "5px" }}>{castDetails?.age}</p>
              <div style={{ fontSize: "22px", color: "white", paddingBottom: "5px" }}>
                <i style={{ paddingRight: "5px" }} className="fa fa-suitcase" aria-hidden="true"></i> OCCUPATION</div>
              <p style={{ paddingTop: "5px" }}>{castDetails?.occupation}</p>



              <div style={{ fontSize: "22px", color: "white", paddingBottom: "5px" }}><i style={{ paddingRight: "5px" }} className="fa fa-flag-o" aria-hidden="true"></i> KEY MOMENTS</div>
              <ul>
                {castDetails?.pivotalMoments ? castDetails.pivotalMoments.split('\n').filter(quote => quote.trim() !== '').map((quote, index) => (
                  <li key={index}>{quote.trim().replace(/^\d+\.\s*/, '')}</li> // Remove leading numbers
                )) : <li>pivotalMoments not available.</li>}
              </ul>
            </div>
          </div>

          <div className="character-card">
            <h5>{castDetails.actor_name?.toUpperCase()} also in...</h5>
            {mediaForPerson.length > 0 ? (
              <span>
                {mediaForPerson.map((media, index) => (
                  <a href={`/details/${media.id}/${encodeURIComponent(media.title || media.name)}/${new Date(media.release_date || media.first_air_date).getFullYear()}/true?media_type=${mediaDetails.media_type}`} key={index} style={{ textDecoration: "none" }}>

                    <img src={`https://image.tmdb.org/t/p/w300/${media?.poster_path}`} alt="" style={{ width: "160px", marginLeft: "10px", cursor: "pointer" }} className="cast-profile-image" />
                  </a>
                ))}
              </span>
            ) : (
              <p>No other media found.</p>
            )}
          </div>





        </div>
      </div>
    </div>
  );
};

export default CastDetails;
