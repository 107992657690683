import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './Cast.css';

const Cast = ({ media_type, tmdb_id, title }) => {
  const [castList, setCastList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fetchingPersonalities, setFetchingPersonalities] = useState(false);
  const [personalitiesFetched, setPersonalitiesFetched] = useState(false);
  const [MBTI, setMBTI] = useState(localStorage.getItem('MBTI') || '    ');

  const MBTINames = {
    'INTJ': 'INTJ - The Architect',
    'INTP': 'INTP - The Logician',
    'ENTJ': 'ENTJ - The Commander',
    'ENTP': 'ENTP - The Debater',
    'INFJ': 'INFJ - The Advocate',
    'INFP': 'INFP - The Mediator',
    'ENFJ': 'ENFJ - The Protagonist',
    'ENFP': 'ENFP - The Campaigner',
    'ISTJ': 'ISTJ - The Logistician',
    'ISFJ': 'ISFJ - The Defender',
    'ESTJ': 'ESTJ - The Executive',
    'ESFJ': 'ESFJ - The Consul',
    'ISTP': 'ISTP - The Virtuoso',
    'ISFP': 'ISFP - The Adventurer',
    'ESTP': 'ESTP - The Entrepreneur',
    'ESFP': 'ESFP - The Entertainer'
  };

  useEffect(() => {
    const fetchCast = async () => {
      setLoading(true);
      try {
        const response = await fetch(`/api/cast/${media_type}/${tmdb_id}`);
        if (!response.ok) throw new Error('Failed to fetch cast details');
        const data = await response.json();
        setCastList(data.filter(member => member.profile_path !== null));
      } catch (err) {
        console.error('Error fetching cast data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCast();
  }, [media_type, tmdb_id]);

  useEffect(() => {
    const anyMissingPersonalities = castList.some(member => !member.personality_profile);
    const fetchPersonalities = async () => {
      if (!anyMissingPersonalities || fetchingPersonalities || personalitiesFetched) return;

      setFetchingPersonalities(true);
      const postData = castList.filter(member => !member.personality_profile).map(member => ({
        cast_id: member.credit_id,
        character_name: member.character
      }));

      try {
        if (personalitiesFetched === false) {
          setPersonalitiesFetched(true);
          const response = await fetch(`/api/personality/${media_type}/${tmdb_id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postData)
          });
          const data = await response.json();
          setCastList(castList.map(cast => ({
            ...cast,
            ...data.find(item => item.cast_id === cast.credit_id.toString())
          })));
        }
       
      } catch (error) {
        console.error('Error fetching personalities:', error);
      } finally {
        setFetchingPersonalities(false);
      }
    };

    fetchPersonalities();
  }, [castList, media_type, tmdb_id, fetchingPersonalities, personalitiesFetched]);

  if (loading) return <div className="cast-grid"><div>Loading cast...</div></div>;
  if (error) return <div>Error fetching cast: {error}</div>;

  const openCastDetailsInNewWindow = (credit_id) => {
    const url = `/cast-details/${credit_id}/${media_type}/${title}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <span style={{color:"gray", fontSize:"14px", marginLeft:"15px"}}>&nbsp;<i style={{color:"gold"}} className='fa fa-star'></i> characters with <a href="/persona-match">your personality</a> profile</span>
      <div className="cast-grid" style={{marginTop:"10px"}}>

        {castList.map((member, index) => (
          <div key={index} className="cast-member" style={{ color: "#fff", textDecoration: "none", cursor: "pointer" }}>
            {member.profile_path && (
              <a href={`/cast-details/${member.credit_id}/${media_type}/${title}`} style={{ textDecoration: "none" }}>
                <img src={`https://image.tmdb.org/t/p/w300/${member.profile_path}`} alt={member.actor_name} className="cast-photo" />
              </a>
            )}
            <div className="cast-info" style={{ fontSize: "16px", alignContent: "top" }}>
              <strong>{member.actor_name}</strong>
              <p style={{ fontSize: "14px", position: "relative", top: "5px", color:"white" }}>
              {member.character}<br />
              <span style={{color:"gray"}}>
              {MBTI.trim() === member.personality_profile ? <i style={{color:"gold", fontSize:"17px", marginRight:"5px"}} className='fa fa-star'></i> : null}
                {member.personality_profile ? MBTINames[member.personality_profile] : "Fetching..."}                
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>

    </>
  );
};

export default Cast;