import Cookies from 'js-cookie';

// Utility function to check and prompt for username
export const checkAndPromptForUsername = () => {
  let username = Cookies.get("username");
  if (!username) {
    // Generate a random hash for the username
    const randomHash = Math.random().toString(36).substring(2, 15) 
                      + Math.random().toString(36).substring(2, 15);
    Cookies.set("username", randomHash, { expires: 30 });
  }
  return Cookies.get("username"); // Return the username
};

// Utility function to add item to local storage
const addToLocalStorage = (mediaType, newItem) => {
  console.log('Adding to local storage:', newItem); 
  console.log(newItem);
  if (mediaType === "movie") {
    mediaType = "movies";
  }
  const key = `sortedWatchList-${mediaType}`;
  const storedList = localStorage.getItem(key);
  const watchList = storedList ? JSON.parse(storedList) : [];

  // Check if the item is already in the list
  const itemExists = watchList.some(item => item.tmdb_id === newItem.tmdb_id);

  if (!itemExists) {
    // Add the new item to the start of the list
    watchList.unshift(newItem);

    // Save the updated list back to localStorage
    localStorage.setItem(key, JSON.stringify(watchList));
  }
};

// Function to add item to watchlist
export const addToWatchlist = async (item, event, onRecommendationsFetched) => {
  event.preventDefault();
  event.stopPropagation();
  checkAndPromptForUsername();
  
  let username = Cookies.get("username");
  if (!username) {
    username = prompt("Please enter your email address for your watchlist:");
    if (!username) return; // Exit if no username is provided
    Cookies.set("username", username, { expires: 30 });
  }
 
  window.triggerWatchlistAnimation();
  let { id, title, media_type, tmdb_id } = item;
  // Add to local storage watchlist

  // This assumes you have a way to identify the closest relevant DOM element
  const targetElement = event.target.closest('.media-item');
  let response_data = null;
  if (tmdb_id !== undefined) {
    id = tmdb_id;
  }
    
  if (targetElement) {
    targetElement.style.opacity = '0'; // Start fade-out effect
    setTimeout(() => {
      // Hide the target element after the animation using display: none
      targetElement.style.display = 'none';
    }, 300); // Match the CSS animation duration
  }

  try {
    // Add to server watchlist
    const response = await fetch(`/w/${username}/add/${id}?title=${encodeURIComponent(title)}&media_type=${media_type}`, {
      method: 'GET'
    });
    response_data = await response.json();
    addToLocalStorage(media_type, response_data.response);

    // Fetch recommendations if callback is provided
    if (onRecommendationsFetched) {
      const recommendationsResponse = await fetch(`/w/recommendations/?id=${id}&media_type=${media_type}`);
      const recommendationsData = await recommendationsResponse.json();
      onRecommendationsFetched(recommendationsData);
    }
  } catch (error) {
    console.error('Error:', error);
  }
  return response_data;
};

const fetchAPI = async (url, options = {}) => {
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return [data, null];
  } catch (error) {
    console.error('Fetch error:', error);
    return [null, error];
  }
};

export { fetchAPI };
